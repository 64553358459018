/* home */

#home .swiper-button-prev {
    color: var(--iq-white);
    right: auto;
    left: 35px;
    z-index: 9;
    display: flex;
    align-items: center;
}

#home .swiper-button-next {
    left: auto;
    color: var(--iq-white);
    right: 35px;
    z-index: 9;
    display: flex;
    align-items: center;
}

#home .swiper-button i {
    font-size: 24px;
}

/* #home .swiper-button-prev:after, .swiper-container-rtl .swiper-button-next:before {content: 'none'} */

/* #home .swiper-button-next:after, .swiper-container-rtl .swiper-button-prev:before {content: 'none'} */
/*  
#home .swiper-button-prev {
    display: flex !important; 
    align-items: center !important; 
    justify-content: center;
    width: 35px; 
    height: 60px; 
    text-align: center; 
    opacity: 1; 
    font-size: 0;
    z-index: 9;
    background: rgba(0, 0, 0, 0.5); 
    margin: 0;
    left: 0; 
    line-height: 5px; 
    box-shadow: 0px 9px 19px #01041B0D; 
    font-size: 0; 
    transform: none; 
    color: var(--iq-white); 
    -webkit-transition: all 0.4s ease-in-out 0s; 
    -moz-transition: all 0.4s ease-in-out 0s; 
    transition: all 0.4s ease-in-out 0s; 
} 

#home .swiper-button-next {
    display: flex; 
    align-items: center; 
    justify-content: center;
    width: 35px; 
    height: 60px; 
    text-align: center; 
    opacity: 1; 
    z-index: 9;
    right: 0; 
    background: rgba(0, 0, 0, 0.5); 
    margin: 0; 
    line-height: 5px; 
    box-shadow: 0px 9px 19px #01041B0D; 
    font-size: 0; 
    transform: none; 
    color: var(--iq-white); 
    -webkit-transition: all 0.4s ease-in-out 0s; 
    -moz-transition: all 0.4s ease-in-out 0s; 
    transition: all 0.4s ease-in-out 0s; 
}  */

/* home page - latest movies slider, movie page - Bollywood Blockbuster slider */

#favorites-contens .swiper-button-prev {
    display: flex;
    align-items: center;
    color: var(--iq-white);
    right: auto;
    left: 9px;
    z-index: 9;

}

#favorites-contens .swiper-button-next {
    left: auto;
    color: var(--iq-white);
    right: 9px;
    z-index: 9;
}

#favorites-contens .swiper-button i {
    font-size: 17px;
}

/* #favorites-contens .swiper-button-prev:after, .swiper-container-rtl .swiper-button-next:before {content: 'none'} */

/* #favorites-contens .swiper-button-next:after, .swiper-container-rtl .swiper-button-prev:before {content: 'none'} */

#favorites-contens .swiper-button-prev {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 60px;
    text-align: center;
    opacity: 1;
    font-size: 0;
    z-index: 9;
    background: rgba(0, 0, 0, 0.5);
    margin: 0;
    left: 15px;
    line-height: 5px;
    box-shadow: 0px 9px 19px #01041B0D;
    font-size: 0;
    transform: none;
    color: var(--iq-white);
    -webkit-transition: all 0.4s ease-in-out 0s;
    -moz-transition: all 0.4s ease-in-out 0s;
    transition: all 0.4s ease-in-out 0s;
}

#favorites-contens .swiper-button-next {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 60px;
    text-align: center;
    opacity: 1;
    z-index: 9;
    right: 15px;
    background: rgba(0, 0, 0, 0.5);
    margin: 0;
    line-height: 5px;
    box-shadow: 0px 9px 19px #01041B0D;
    font-size: 0;
    transform: none;
    color: var(--iq-white);
    -webkit-transition: all 0.4s ease-in-out 0s;
    -moz-transition: all 0.4s ease-in-out 0s;
    transition: all 0.4s ease-in-out 0s;
}

/* home page - upcoming slider, movie page - bengali movies */

#upcoming-contens .swiper-button-prev {
    color: var(--iq-white);
    right: auto;
    left: 9px;
    z-index: 9;
    display: flex;
    align-items: center;
}

#upcoming-contens .swiper-button-next {
    left: auto;
    color: var(--iq-white);
    right: 6px;
    z-index: 9;
    display: flex;
    align-items: center;
}

#upcoming-contens .swiper-button i {
    font-size: 17px;
}

#upcoming-contens .swiper-button-prev {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 60px;
    text-align: center;
    opacity: 1;
    font-size: 0;
    z-index: 9;
    background: rgba(0, 0, 0, 0.5);
    margin: 0;
    left: 15px;
    line-height: 5px;
    box-shadow: 0px 9px 19px #01041B0D;
    font-size: 0;
    transform: none;
    color: var(--iq-white);
    -webkit-transition: all 0.4s ease-in-out 0s;
    -moz-transition: all 0.4s ease-in-out 0s;
    transition: all 0.4s ease-in-out 0s;
}

#upcoming-contens .swiper-button-next {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 60px;
    text-align: center;
    opacity: 1;
    z-index: 9;
    right: 15px;
    background: rgba(0, 0, 0, 0.5);
    margin: 0;
    line-height: 5px;
    box-shadow: 0px 9px 19px #01041B0D;
    font-size: 0;
    transform: none;
    color: var(--iq-white);
    -webkit-transition: all 0.4s ease-in-out 0s;
    -moz-transition: all 0.4s ease-in-out 0s;
    transition: all 0.4s ease-in-out 0s;
}

/* movies we suggested slider*/

#suggestede-contens .swiper-button-prev {
    color: var(--iq-white);
    right: auto;
    left: 9px;
    z-index: 9;
    display: flex;
    align-items: center;
}

#suggestede-contens .swiper-button-next {
    left: auto;
    color: var(--iq-white);
    right: 6px;
    z-index: 9;
    display: flex;
    align-items: center;
}

#suggestede-contens .swiper-button i {
    font-size: 17px;
}

#suggestede-contens .swiper-button-prev {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 60px;
    text-align: center;
    opacity: 1;
    font-size: 0;
    z-index: 9;
    background: rgba(0, 0, 0, 0.5);
    margin: 0;
    left: 15px;
    line-height: 5px;
    box-shadow: 0px 9px 19px #01041B0D;
    font-size: 0;
    transform: none;
    color: var(--iq-white);
    -webkit-transition: all 0.4s ease-in-out 0s;
    -moz-transition: all 0.4s ease-in-out 0s;
    transition: all 0.4s ease-in-out 0s;
}

#suggestede-contens .swiper-button-next {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 60px;
    text-align: center;
    opacity: 1;
    z-index: 9;
    right: 15px;
    background: rgba(0, 0, 0, 0.5);
    margin: 0;
    line-height: 5px;
    box-shadow: 0px 9px 19px #01041B0D;
    font-size: 0;
    transform: none;
    color: var(--iq-white);
    -webkit-transition: all 0.4s ease-in-out 0s;
    -moz-transition: all 0.4s ease-in-out 0s;
    transition: all 0.4s ease-in-out 0s;
}

/* Trending slider */

#trending-contens .swiper-button-prev {
    color: var(--iq-white);
    right: auto;
    top: 105px;
    left: 9px;
    z-index: 9;
    display: flex !important;
    align-items: center !important;
    transition: all 0.6s ease 0s;
}

#trending-contens .swiper-button-next {
    left: auto;
    top: 105px;
    color: var(--iq-white);
    right: 9px;
    z-index: 9;
    display: flex !important;
    align-items: center !important;
    transition: all 0.6s ease 0s;
}

#trending-contens .swiper-slide.swiper-slide-duplicate.swiper-slide-prev {
    transition: all 0.6s ease 0s;
}

#trending-contens .swiper-slide.swiper-slide-duplicate.swiper-slide-active {
    transition: all 0.6s ease 0s;
}

#trending-contens .swiper-slide.swiper-slide-duplicate.swiper-slide-next {
    transition: all 0.6s ease 0s;
}

#trending-contens .swiper-slide.swiper-slide-duplicate {
    transition: all 0.6s ease 0s;
}

.swiper-button i {
    font-size: 17px;
}

.swiper-button-prev:after,
.swiper-container-rtl .swiper-button-next:before {
    content: 'none'
}

.swiper-button-next:after,
.swiper-container-rtl .swiper-button-prev:before {
    content: 'none'
}

#trending-contens .swiper-button-prev {
    display: flex !important;
    align-items: center !important;
    justify-content: center;
    width: 35px;
    height: 60px;
    text-align: center;
    opacity: 1;
    font-size: 0;
    z-index: 9;
    background: rgba(0, 0, 0, 0.5);
    margin: 0;
    left: 15px;
    line-height: 5px;
    box-shadow: 0px 9px 19px #01041B0D;
    font-size: 0;
    transform: none;
    color: var(--iq-white);
    -webkit-transition: all 0.4s ease-in-out 0s;
    -moz-transition: all 0.4s ease-in-out 0s;
    transition: all 0.4s ease-in-out 0s;
}

#trending-contens .swiper-button-next {
    display: flex !important;
    align-items: center !important;
    justify-content: center;
    width: 35px;
    height: 60px;
    text-align: center;
    opacity: 1;
    z-index: 9;
    right: 15px;
    background: rgba(0, 0, 0, 0.5);
    margin: 0;
    line-height: 5px;
    box-shadow: 0px 9px 19px #01041B0D;
    font-size: 0;
    transform: none;
    color: var(--iq-white);
    -webkit-transition: all 0.4s ease-in-out 0s;
    -moz-transition: all 0.4s ease-in-out 0s;
    transition: all 0.4s ease-in-out 0s;
}

/* Thriller slider */

#tvthrillers-contens .swiper-button-prev {
    color: var(--iq-white);
    right: auto;
    left: 9px;
    z-index: 9;
    display: flex;
    align-items: center;
}

#tvthrillers-contens .swiper-button-next {
    left: auto;
    color: var(--iq-white);
    right: 9px;
    z-index: 9;
    display: flex;
    align-items: center;
}

#tvthrillers-contens .swiper-button i {
    font-size: 17px;
}

.swiper-button-prev:after,
.swiper-container-rtl .swiper-button-next:before {
    content: 'none'
}

.swiper-button-next:after,
.swiper-container-rtl .swiper-button-prev:before {
    content: 'none'
}

#tvthrillers-contens .swiper-button-prev {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 60px;
    text-align: center;
    opacity: 1;
    font-size: 0;
    z-index: 9;
    background: rgba(0, 0, 0, 0.5);
    margin: 0;
    left: 15px;
    line-height: 5px;
    box-shadow: 0px 9px 19px #01041B0D;
    font-size: 0;
    transform: none;
    color: var(--iq-white);
    -webkit-transition: all 0.4s ease-in-out 0s;
    -moz-transition: all 0.4s ease-in-out 0s;
    transition: all 0.4s ease-in-out 0s;
}

#tvthrillers-contens .swiper-button-next {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 60px;
    text-align: center;
    opacity: 1;
    z-index: 9;
    right: 15px;
    background: rgba(0, 0, 0, 0.5);
    margin: 0;
    line-height: 5px;
    box-shadow: 0px 9px 19px #01041B0D;
    font-size: 0;
    transform: none;
    color: var(--iq-white);
    -webkit-transition: all 0.4s ease-in-out 0s;
    -moz-transition: all 0.4s ease-in-out 0s;
    transition: all 0.4s ease-in-out 0s;
}

/* movie show */

#movieshow .swiper-button-prev {
    color: var(--iq-white);
    right: auto;
    left: 35px;
    z-index: 9;
    display: flex;
    align-items: center;
}

#movieshow .swiper-button-next {
    left: auto;
    color: var(--iq-white);
    right: 35px;
    z-index: 9;
    display: flex;
    align-items: center;
}

#movieshow .swiper-button i {
    font-size: 65px;
}

/* .swiper-button-prev:after, .swiper-container-rtl .swiper-button-next:before {content: 'none'} */

/* .swiper-button-next:after, .swiper-container-rtl .swiper-button-prev:before {content: 'none'} */

/* tvshow */

#tvshow .swiper-slide.swiper-slide-prev {
    /* width: 100px; */
    filter: blur(4px);
}

#tvshow .swiper-slide.swiper-slide-duplicate.swiper-slide-next {
    /* width: 100px; */
    filter: blur(4px);
}

#tvshow .swiper-slide.swiper-slide-next {
    filter: blur(4px);
}


#tvshow .swiper-button-prev {
    color: var(--iq-white);
    right: auto;
    left: 35px;
    z-index: 9;
    display: flex;
    align-items: center;
}

#tvshow .swiper-button-next {
    left: auto;
    color: var(--iq-white);
    right: 35px;
    z-index: 9;
    display: flex;
    align-items: center;
}

#tvshow .swiper-button i {
    font-size: 65px;
}

/* .swiper-button-prev:after, .swiper-container-rtl .swiper-button-next:before {content: 'none'} */

/* .swiper-button-next:after, .swiper-container-rtl .swiper-button-prev:before {content: 'none'} */

/* Top 10 slider */

#iq-topten .swiper-button-prev {
    display: flex;
    align-items: center;
    color: var(--iq-white);
    right: auto;
    left: 9px;
    z-index: 9;

}

#iq-topten .swiper-button-next {
    left: auto;
    color: var(--iq-white);
    right: 9px;
    z-index: 9;
}

#iq-topten .swiper-button i {
    font-size: 17px;
}

/* #iq-topten .swiper-button-prev:after, .swiper-container-rtl .swiper-button-next:before {content: 'none'} */

/* #iq-topten .swiper-button-next:after, .swiper-container-rtl .swiper-button-prev:before {content: 'none'} */

#iq-topten .swiper-button-prev {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 55px;
    height: 35px;
    text-align: center;
    opacity: 1;
    font-size: 0;
    z-index: 9;
    background: rgba(0, 0, 0, 0.5);
    margin: 0;
    left: 15px;
    line-height: 5px;
    box-shadow: 0px 9px 19px #01041B0D;
    font-size: 0;
    transform: none;
    color: var(--iq-white);
    -webkit-transition: all 0.4s ease-in-out 0s;
    -moz-transition: all 0.4s ease-in-out 0s;
    transition: all 0.4s ease-in-out 0s;
    top: 700px;
    margin-left: 60px;
}

#iq-topten .swiper-button-next {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 55px;
    height: 35px;
    text-align: center;
    opacity: 1;
    z-index: 9;
    top: 59px;
    right: 1200px;
    left: 60px;
    background: rgba(0, 0, 0, 0.5);
    margin: 0;
    line-height: 5px;
    box-shadow: 0px 9px 19px #01041B0D;
    font-size: 0;
    transform: none;
    color: var(--iq-white);
    -webkit-transition: all 0.4s ease-in-out 0s;
    -moz-transition: all 0.4s ease-in-out 0s;
    transition: all 0.4s ease-in-out 0s;
}

.height {
    height: 143px;
}

/* episode 1 */

#episode1 .swiper-button i {
    font-size: 17px;
}

#episode1 .swiper-button-prev {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 60px;
    text-align: center;
    opacity: 1;
    font-size: 0;
    z-index: 9;
    background: rgba(0, 0, 0, 0.5);
    margin: 0;
    left: 0;
    top: 66%;
    line-height: 5px;
    box-shadow: 0px 9px 19px #01041B0D;
    font-size: 0;
    transform: none;
    color: var(--iq-white);
    -webkit-transition: all 0.4s ease-in-out 0s;
    -moz-transition: all 0.4s ease-in-out 0s;
    transition: all 0.4s ease-in-out 0s;
}

#episode1 .swiper-button-next {
    width: 35px;
    height: 60px;
    text-align: center;
    opacity: 1;
    z-index: 9;
    right: 0;
    top: 66%;
    background: rgba(0, 0, 0, 0.5);
    margin: 0;
    line-height: 5px;
    box-shadow: 0px 9px 19px #01041B0D;
    font-size: 0;
    transform: none;
    color: var(--iq-white);
    -webkit-transition: all 0.4s ease-in-out 0s;
    -moz-transition: all 0.4s ease-in-out 0s;
    transition: all 0.4s ease-in-out 0s;
}

/* episode2 */


#episode2 .swiper-button-prev {
    color: var(--iq-white);
    right: auto;
    left: 9px;
    z-index: 9;
    display: flex;
    align-items: center;
}

#episode2 .swiper-button-next {
    left: auto;
    color: var(--iq-white);
    right: 9px;
    z-index: 9;
    display: flex;
    align-items: center;
}

#episode2 .swiper-button i {
    font-size: 17px;
}

.swiper-button-prev:after,
.swiper-container-rtl .swiper-button-next:before {
    content: 'none'
}

.swiper-button-next:after,
.swiper-container-rtl .swiper-button-prev:before {
    content: 'none'
}

#episode2 .swiper-button-prev {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 60px;
    text-align: center;
    opacity: 1;
    font-size: 0;
    z-index: 9;
    background: rgba(0, 0, 0, 0.5);
    margin: 0;
    left: 0;
    top: 62%;
    line-height: 5px;
    box-shadow: 0px 9px 19px #01041B0D;
    font-size: 0;
    transform: none;
    color: var(--iq-white);
    -webkit-transition: all 0.4s ease-in-out 0s;
    -moz-transition: all 0.4s ease-in-out 0s;
    transition: all 0.4s ease-in-out 0s;
}

#episode2 .swiper-button-next {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 60px;
    text-align: center;
    opacity: 1;
    z-index: 9;
    right: 0;
    top: 62%;
    background: rgba(0, 0, 0, 0.5);
    margin: 0;
    line-height: 5px;
    box-shadow: 0px 9px 19px #01041B0D;
    font-size: 0;
    transform: none;
    color: var(--iq-white);
    -webkit-transition: all 0.4s ease-in-out 0s;
    -moz-transition: all 0.4s ease-in-out 0s;
    transition: all 0.4s ease-in-out 0s;
}

.verticalcss {
    transform: rotate(90deg);
    display: flex;
    left: -600px;

}

.parallax-window {

    z-index: 5;
}

footer {
    z-index: -1;
}


@media (max-width: 415px) {
    #iq-topten .swiper-button-next {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 45px;
        height: 35px;
        text-align: center;
        opacity: 1;
        z-index: 9;
        top: 80px;
        right: 1200px;
        left: 0;
        background: rgba(0, 0, 0, 0.5);
        margin: 0;
        line-height: 5px;
        box-shadow: 0px 9px 19px #01041B0D;
        font-size: 0;
        transform: none;
        color: var(--iq-white);
        -webkit-transition: all 0.4s ease-in-out 0s;
        -moz-transition: all 0.4s ease-in-out 0s;
        transition: all 0.4s ease-in-out 0s;

        left: 10px;
        transform: rotate(-90deg);
    }


    #iq-topten .swiper-button-prev {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 45px;
        height: 35px;
        text-align: center;
        opacity: 1;
        font-size: 0;
        z-index: 9;
        background: rgba(0, 0, 0, 0.5);
        margin: 0;
        left: 0;
        line-height: 5px;
        box-shadow: 0px 9px 19px #01041B0D;
        font-size: 0;
        transform: none;
        color: var(--iq-white);
        -webkit-transition: all 0.4s ease-in-out 0s;
        -moz-transition: all 0.4s ease-in-out 0s;
        transition: all 0.4s ease-in-out 0s;
        top: 80px;
        margin-left: 84%;
        transform: rotate(-90deg);

        z-index: 1000;
    }

}

@media (min-width: 768px) and (max-width: 1024px) {
    #iq-topten .swiper-button-next {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 55px;
        height: 35px;
        text-align: center;
        opacity: 1;
        z-index: 9;
        top: 190px;
        right: 1200px;
        left: 60px;
        background: rgba(0, 0, 0, 0.5);
        margin: 0;
        line-height: 5px;
        align-items: center;
    }

    #episode2 .swiper-button-next {
        left: auto;
        color: var(--iq-white);
        right: 9px;
        z-index: 9;
        display: flex;
    }

    #iq-topten .swiper-button-prev {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 55px;
        height: 35px;
        text-align: center;
        opacity: 1;
        font-size: 0;
        z-index: 9;
        background: rgba(0, 0, 0, 0.5);
        margin: 0;
        left: 0;
        line-height: 5px;
        box-shadow: 0px 9px 19px #01041B0D;
        font-size: 0;
        transform: none;
        color: var(--iq-white);
        -webkit-transition: all 0.4s ease-in-out 0s;
        -moz-transition: all 0.4s ease-in-out 0s;
        transition: all 0.4s ease-in-out 0s;
        top: 190px;
        margin-left: 92%;
        transform: rotate(-90deg);

        z-index: 1000;
    }

}

/* 
     
.swiper2{
    background-image: url(../../assets/images/movie-banner/2.jpg);
}

.swiper3{
    background-image: url(../../assets/images/movie-banner/3.jpg);
}

.swiper4{
    background-image: url(../../assets/images/movie-banner/1.jpg);
} */

@media (min-width: 320px) and (max-width: 1000px) {
    #home .swiper-button i {
        display: none;

    }

    .swiper-container-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet {
        height: 3px;
        width: 40px;
        background: #bc4203;
        ;
        padding: 0;
        margin-bottom: 15px;

    }

    #movieshow .swiper-button i {
        display: none;

    }

    .form1 {
        width: 100% !important
    }

    /* .form2{margin-right: 0 !important;} */
    /* #favorites-contens .block-description {
        display: none;
    } */
    #favorites-contens .swiper-slide:hover .block-social-info {
        display: none;
    }

    /* #favorites-contens .swiper-slide:hover .block-images{
        display: none !important;
    } 
    #favorites-contens .swiper-slide:hover .block-images1{
        display: block !important;
    }  */
    /* #favorites-contens .swiper-slide:hover .block-images1{
        display: block;
    }
    #favorites-contens .swiper-slide:hover .block-images{
        display: block;
    } */

    /* #upcoming-contens .block-description{
        display: none;
    } */
    #upcoming-contens .swiper-slide:hover .block-social-info {
        display: none;
    }

    /* #upcoming-contens .swiper-slide:hover .block-images1{
        display: block;
    }
    #upcoming-contens .swiper-slide:hover .block-images{
        display: block;
    } */
    .form1 {
        display: block
    }

    /* #suggestede-contens .block-description{
        display: none;
    } */
    #suggestede-contens .swiper-slide:hover .block-social-info {
        display: none;
    }

    /* #suggestede-contens .swiper-slide:hover .block-images1{
        display: block;
    }
    #suggestede-contens .swiper-slide:hover .block-images{
        display: block;
    } */
    /* 
    #tvthrillers-contens .block-description{
        display: none;
    } */
    #tvthrillers-contens .swiper-slide:hover .block-images1 {
        display: block;
    }

    /* #tvthrillers-contens .swiper-slide:hover .block-images{
        display: block;
    }
    #tvthrillers-contens .swiper-slide:hover .block-social-info{
        display: none;
    } */

    #trending-contens .swiper-slide-active .movie-slick {
        transition: none;
        padding: unset;
        background: unset !important;
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(1px);
        border: unset;
    }

    #tvshow .swiper-button i {
        display: none;
    }

    #home .swiper-button i {
        display: none;
    }

    #trending-contens .swiper-slide.swiper-slide-active {
        width: 0px !important;
        margin: 0px !important;
    }

}

/* userprofile */

.bgcollor {
    background-color: #000000;
}

.css-g1d714-ValueContainer {
    background-color: #000000;
}

.css-yk16xz-control .css-26l3qy-menu {
    background-color: #000000;

}


.react-datepicker__header {
    background-color: #000000;
}

.react-datepicker__month {
    background-color: #000000;

}

.react-datepicker__month-container {
    background-color: #000000;

}

.react-datepicker__day {
    color: white;
}

.react-datepicker__current-month {
    color: white;
}

.react-datepicker__day-name {
    color: white;
}

.react-datepicker-popper[data-placement^=bottom] {
    z-index: 15;
}

.swiper-slide-active .movie-slick {

    padding: 10px;
    background: rgba(20, 20, 20, 0.5) !important;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(1px);
    border: 1px solid #5d5757;
    transition: all 0.6s ease 0s;

}

/* 
#movieshow .swiper-slide.swiper-slide-active{
    width: 1000px;
} */
#movieshow .swiper-slide.swiper-slide-prev {
    /* width: 100px; */
    filter: blur(4px);
}

#movieshow .swiper-slide.swiper-slide-next {
    /* width: 100px; */
    filter: blur(4px);
}


/* trending episode2 */


/* #episode2  .swiper-button-prev{ 
    color: var(--iq-white);
    right: auto;
    left: 9px;
    z-index: 9;
    display: flex;
    align-items: center;  
}
#episode2 .swiper-button-next{ 
    left: auto;
    color: var(--iq-white);
    right: 9px;
    z-index: 9;
    display: flex !important;
    align-items: center !important;  
}

#episode2 .swiper-button i{font-size: 17px;}
 
#episode2 .swiper-button-prev {
    display: flex; 
    align-items: center; 
    justify-content: center;
    width: 35px; 
    height: 60px; 
    text-align: center; 
    opacity: 1; 
    font-size: 0;
    z-index: 9;
    background: rgba(0, 0, 0, 0.5); 
    margin: 0;
    left: 0; 
    line-height: 5px; 
    box-shadow: 0px 9px 19px #01041B0D; 
    font-size: 0; 
    transform: none; 
    color: var(--iq-white); 
    -webkit-transition: all 0.4s ease-in-out 0s; 
    -moz-transition: all 0.4s ease-in-out 0s; 
    transition: all 0.4s ease-in-out 0s; 
    top:68%;
} 

#episode2 .swiper-button-next {
    display: flex; 
    align-items: center; 
    justify-content: center;
    width: 35px; 
    height: 60px; 
    text-align: center; 
    opacity: 1; 
    z-index: 9;
    right: 0; 
    background: rgba(0, 0, 0, 0.5); 
    margin: 0; 
    line-height: 5px; 
    box-shadow: 0px 9px 19px #01041B0D; 
    font-size: 0; 
    transform: none; 
    color: var(--iq-white); 
    -webkit-transition: all 0.4s ease-in-out 0s; 
    -moz-transition: all 0.4s ease-in-out 0s; 
    transition: all 0.4s ease-in-out 0s; 
    top:68%;
}   */
/* episode 3 */

#episode3 .swiper-button-prev {
    color: var(--iq-white);
    right: auto;
    left: 9px;
    z-index: 9;
    display: flex;
    align-items: center;
}

#episode3 .swiper-button-next {
    left: auto;
    color: var(--iq-white);
    right: 9px;
    z-index: 9;
    display: flex !important;
    align-items: center !important;
}

#episode3 .swiper-button i {
    font-size: 24px;
}

.swiper-button-prev:after,
.swiper-container-rtl .swiper-button-next:before {
    content: 'none'
}

.swiper-button-next:after,
.swiper-container-rtl .swiper-button-prev:before {
    content: 'none'
}

#episode3 .swiper-button-prev {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 60px;
    text-align: center;
    opacity: 1;
    font-size: 0;
    z-index: 9;
    background: rgba(0, 0, 0, 0.5);
    margin: 0;
    left: 0;
    line-height: 5px;
    box-shadow: 0px 9px 19px #01041B0D;
    font-size: 0;
    transform: none;
    color: var(--iq-white);
    -webkit-transition: all 0.4s ease-in-out 0s;
    -moz-transition: all 0.4s ease-in-out 0s;
    transition: all 0.4s ease-in-out 0s;
    top: 60%;
}

#episode3 .swiper-button-next {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 60px;
    text-align: center;
    opacity: 1;
    z-index: 9;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
    margin: 0;
    line-height: 5px;
    box-shadow: 0px 9px 19px #01041B0D;
    font-size: 0;
    transform: none;
    color: var(--iq-white);
    -webkit-transition: all 0.4s ease-in-out 0s;
    -moz-transition: all 0.4s ease-in-out 0s;
    transition: all 0.4s ease-in-out 0s;
    top: 60%;
}

/* episode 4 */

/* #episode4  .swiper-button-prev{ 
    color: var(--iq-white);
    right: auto;
    left: 9px;
    z-index: 9;
    display: flex;
    align-items: center;  
}
#episode4 .swiper-button-next{ 
    left: auto;
    color: var(--iq-white);
    right: 9px;
    z-index: 9;
    display: flex !important;
    align-items: center !important;  
}


#episode4 .swiper-button i{font-size: 17px;}

.swiper-button-prev:after, .swiper-container-rtl .swiper-button-next:before {content: 'none'}

.swiper-button-next:after, .swiper-container-rtl .swiper-button-prev:before {content: 'none'}
 
#episode4 .swiper-button-prev {
    display: flex; 
    align-items: center; 
    justify-content: center;
    width: 35px; 
    height: 60px; 
    text-align: center; 
    opacity: 1; 
    font-size: 0;
    z-index: 9;
    background: rgba(0, 0, 0, 0.5); 
    margin: 0;
    left: 0; 
    line-height: 5px; 
    box-shadow: 0px 9px 19px #01041B0D; .
    font-size: 0; 
    transform: none; 
    color: var(--iq-white); 
    -webkit-transition: all 0.4s ease-in-out 0s; 
    -moz-transition: all 0.4s ease-in-out 0s; 
    transition: all 0.4s ease-in-out 0s; 
    top:68%;
} 

#episode4 .swiper-button-next {
    display: flex; 
    align-items: center; 
    justify-content: center;
    width: 35px; .
    height: 60px; 
    text-align: center; 
    opacity: 1; 
    z-index: 9;.
    right: 0; 
    background: rgba(0, 0, 0, 0.5); 
    margin: 0; 
    line-height: 5px; 
    box-shadow: 0px 9px 19px #01041B0D; 
    font-size: 0; 
    transform: none; .
    color: var(--iq-white); 
    -webkit-transition: all 0.4s ease-in-out 0s; 
    -moz-transition: all 0.4s ease-in-out 0s; 
    transition: all 0.4s ease-in-out 0s; 
    top:68%;
}   */

/* episode 5 */

/* #episode5  .swiper-button-prev{ 
    color: var(--iq-white);
    right: auto;.
    left: 9px;
    z-index: 9;
    display: flex;
    align-items: center;    height:3px;
    width:40px;
    background: #bc4203;;
    padding: 0;
    margin-bottom: 15px;l .swiper-button-next:before {content: 'none'}

.swiper-button-next:after, .swiper-container-rtl .swiper-button-prev:before {content: 'none'}
   height:3px;
    width:40px;
    background: #bc4203;;
    padding: 0;
    margin-bottom: 15px;
    transform: none; 
    color: var(--iq-white); 
    -webkit-transition: all 0.4s ease-in-out 0s; .
    -moz-transition: all 0.4s ease-in-out 0s; 
    transition: all 0.4s ease-in-out 0s; 
    top:68%;
} 

#episode5 .swiper-button-next {
    display: flex; 
    align-items: center; .
    justify-content: center;
    width: 35px; 
    height: 60px; 
    text-align: center; 
    opacity: 1;   height:3px;
    width:40px;
    background: #bc4203;;
    padding: 0;
    margin-bottom: 15px;
    z-index: 9;
    right: 0; 
    background: rgba(0, 0, 0, 0.5); 
    margin: 0; 
    line-height: 5px;   height:3px;
    width:40px;
    background: #bc4203;;
    padding: 0;
    margin-bottom: 15px;; 
    -moz-transition: all 0.4s ease-in-out 0s; 
    transition: all 0.4s ease-in-out 0s; 
    top:68%;
}   */

/* episode 6 */

/* #episode6  .swiper-button-prev{ 
    color: var(--iq-white);
    right: auto;
    left: 9px;
    z-index: 9;
    display: flex;
    align-items: center;  
}
#episode6 .swiper-button-next{ 
    left: auto;
    color: var(--iq-white);
    right: 9px;
    z-index: 9;
    display: flex !important;deg
    align-items: center !important;  
}

#episode6 .swiper-button i{font-size: 17px;}

.swiper-button-prev:after, .swiper-container-rtl .swiper-button-next:before {content: 'none'}

.swiper-button-next:after, .swiper-degcontainer-rtl .swiper-button-prev:before {content: 'none'}
 
#episode6 .swiper-button-prev {
    display: flex; 
    align-items: center; 
    justify-content: center;
    width: 35px; 
    height: 60px; 
    text-align: center; 
    opacity: 1; 
    font-size: 0;
    z-index: 9;
    background: rgba(0, 0, 0, 0.5); 
    margin: 0;
    left: 0; 
    line-height: 5px; 
    box-shadow: 0px 9px 19px #01041B0D; 
    font-size: 0; 
    transform: none; 
    color: var(--iq-white); 
    -webkit-transition: all 0.4s ease-in-out 0s; 
    -moz-transition: all 0.4s ease-in-out 0s; 
    transition: all 0.4s ease-in-out 0s; 
    top:68%;
}   height:3px;
    width:40px;
    background: #bc4203;;
    padding: 0;
    margin-bottom: 15px;
    right: 0; 
    background: rgba(0, 0, 0, 0.5); 
    margin: 0; 
    line-height: 5px; 
    box-shadow: 0px 9px 19px #01041B0D; 
    font-size: 0;   height:3px;
    width:40px;
    background: #bc4203;;
    padding: 0;
    margin-bottom: 15px;
    -webkit-transition: all 0.4s ease-in-out 0s; 
    -moz-transition: all 0.4s ease-in-out 0s; 
    transition: all 0.4s ease-in-out 0s; 
    top:68%;
}   */
.css-1hb7zxy-IndicatorsContainer {
    background-color: #000000 !important;
}

.css-26l3qy-menu {
    background-color: #000000 !important;
    z-index: 15 !important;
}

#trending-contens .swiper-container {
    padding-bottom: 25px !important;
    padding-top: 25px !important;
}

#trending-contens .swiper-slide.swiper-slide-active {
    -webkit-transform: scale3d(1.3, 1.3, 1) translate3d(0, 0, 0) perspective(500px);
    width: 270px;
    -webkit-transition: all 0.6s ease-in-out 0s;
    -moz-transition: all 0.6s ease-in-out 0s;
    transition: all 0.6s ease-in-out 0s;

}

#trending-contens .swiper-wrapper {
    display: flex !important;
    align-items: center !important;
}

#trending-contens .swiper-slide.swiper-slide-next {
    z-index: -1;
}

#favorites-contens .swiper-container {
    padding-top: 20px !important;
    padding-bottom: 20px !important;

}

#upcoming-contens .swiper-container {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
}

#suggestede-contens .swiper-container {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
}

#tvthrillers-contens .swiper-container {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
}

.form-control1 {
    height: 62px !important;
}

.css-2b097c-container:hover {
    border: none !important;
}

.css-2613qy-menu:active {
    background: red !important;
    color: white !important
}

/* svg color */
.st11 {
    fill: #bc4203;
}

.st12 {
    fill: #ACACAC;
}

.st9 {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: #bc4203;
}

.st10 {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: #ADADAD;
}

.st0 {
    fill: #bc4203;
}

.st1 {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: #8E8E8E;
}

.st2 {
    fill: #A0A0A0;
}

.st4 {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: #ACACAC;
}

.st5 {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: #bc4203;
}

.st6 {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: #969696;
}

.st7 {
    fill: #ACACAC;
}

.st8 {
    fill: #bc4203;
}

#favorites-contens .swiper-slide.swiper-slide-active:hover .block-images {
    transform: scale3d(1.1, 1.1, 1) translate3d(6%, 0, 0) perspective(500px);
}

#upcoming-contens .swiper-slide.swiper-slide-active:hover .block-images {
    transform: scale3d(1.1, 1.1, 1) translate3d(6%, 0, 0) perspective(500px);
}

#tvthrillers-contens .swiper-slide.swiper-slide-active:hover .block-images {
    transform: scale3d(1.1, 1.1, 1) translate3d(6%, 0, 0) perspective(500px);
}

#suggestede-contens .swiper-slide.swiper-slide-active:hover .block-images {
    transform: scale3d(1.1, 1.1, 1) translate3d(6%, 0, 0) perspective(500px);
}


/* home page swiper1 dots */
/* .swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
    height:3px;
    width:40px;
    background: #bc4203;;
    padding: 0;
    margin-bottom: 15px;
    
}  */

@media (min-width: 1024px) and (max-width: 3822px) {
    .swiper-pagination-bullets .swiper-pagination-bullet {
        display: none;
    }
}

div#f1 {
    width: 115px !important;
}

#suggestede-contens .position-relative {
    transition: all 0.6s ease 0s;
}