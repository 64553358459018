/*------------- Variable ------------*/

:root {
  --iq-primary: linear-gradient(to right, #f06922, #bc4203);
  --iq-primary-hover: linear-gradient(to right, #bc4203, #ff7f30);
  --iq-video-play-button: #bc4203;
  --iq-light-primary: #d7d3f8;
  --iq-secondary: #6c757d;
  --iq-white: #ffffff;
  --iq-black: #000000;
  --iq-title-text: #23203c;
  --iq-body-text: #D1D0CF;
  --iq-body-bg: #000000;
  --iq-bg1: #000000;
  --iq-body-border: #393750;
  --iq-gold: #E6C75F;
  --iq-black: #000000;
  --iq-form-gradient-color: rgba(11, 1, 2, 0);
  --iq-to-gradient-color: rgba(255, 55, 65, 0.3);
}