.react-tel-input .country-list .country.highlight{
    border-radius:10px;
    background-color:rgb(0,0,0,1) !important;
   }
  
   .react-tel-input .flag-dropdown.open .selected-flag{
    background-color:rgb(0,0,0,1) !important;

   }

   .form-control:focus{
    color: white !important;
   }
   .select-dropdown{
    border-radius: 1rem !important;
    background-color:rgb(0,0,0,1) !important;
    border: 1px solid #404043 !important;
    height: 48px !important;
   }
   .file{
    padding: 0 !important;
    line-height: 42px !important;
   }


   .signin{
    /* background-image: url(../../../../assets/regal_images/login_bg_1.png); */
    /* background-image: url(../../../../assets/images/check.gif); */
    width: 95%;
    margin: 0 auto;
    background-size: cover;
    height: 100vh;
    
   }

   @media only screen and (min-width:1000px) {
    .signin{
        /* background-image: url(../../../../assets/regal_images/login_bg_1.png); */
        /* background-image: url(../../../../assets/images/check.gif); */
    }    
   }
